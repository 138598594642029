import * as yup from 'yup';

import { Container, DivFlexRow, FormWrapper } from '../../style';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AlertError } from '../../componets/Alert/Alert';
import BreadCrumb from '../../componets/Breadcrumb/BreadCrumb';
import { Btn } from '../../componets/Buttons/Buttons';
import { ELanguages } from '../../localization';
import { Formik } from 'formik';
import { IGetUsersBE } from '../../model';
import Loading from '../../componets/Loading/Loading';
import { LoggedUserContext } from '../../App';
import Select from 'react-select';
import { TextInput } from '../../componets/Input/Input';
import httpClient from '../../api/httpClient';
import { route } from '../../route';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';
import { useStructures } from '../../api/fetch';
import { useTranslation } from 'react-i18next';

interface INewUserProps {
    userToEdit?: IGetUsersBE;
}

const InitialUser = { name: '', surname: '', email: '', userStructures: [], allowedFlows: [] };
const SelectWrapper = styled.div``;

const NewUser = (props: INewUserProps) => {
    const { t, i18n } = useTranslation();
    const { userToEdit } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const { loggedUser } = useContext(LoggedUserContext);
    const { structures, isLoading } = useStructures(loggedUser.userId);
    const { allowedFlows } = loggedUser;
    const { trigger: triggerEdit } = useSWRMutation('User/Edit', httpClient.putRequest);
    const { trigger: triggerCreate } = useSWRMutation('User/Create', httpClient.postRequest);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const userSchema = yup.object({
        name: yup.string().nullable().required(t('generic.fieldRequired')),
        surname: yup.string().nullable().required(t('generic.fieldRequired')),
        email: yup.string().nullable().email(t('generic.invalidEmail')),
    });

    const checkFlows = (
        flows: {
            value: string;
            label: string;
            id: string;
        }[],
    ) => {
        const sdorPresent = flows.some(flow => flow.label.toUpperCase() === 'SDO-R');
        if (sdorPresent) {
            const sdoPresent = flows.some(flow => flow.label.toUpperCase() === 'SDO');
            return sdoPresent;
        }
        return true;
    };

    const getInitialValues = () => {
        if (userToEdit && structures && structures.length > 0) {
            return {
                name: userToEdit.userName,
                surname: userToEdit.userSurname,
                userStructures: structures
                    .filter(s => userToEdit.structures.some(us => (i18n.language === ELanguages.it ? us.structureNameIt === s.structureNameIt : us.structureNameDe === s.structureNameDe)))
                    .map(s => ({
                        value: s.structureId,
                        label: i18n.language === ELanguages.it ? s.structureNameIt : s.structureNameDe,
                        id: s.structureId,
                    })),
                allowedFlows: userToEdit.allowedFlows
                    .filter(d => d.isAllowed)
                    .map(s => ({
                        value: s.id,
                        label: s.name,
                        id: s.id,
                    })),
                email: userToEdit.userEmail,
            };
        } else {
            return InitialUser;
        }
    };

    return (
        <Container>
            {!userToEdit && (
                <BreadCrumb
                    paths={[
                        { nameToShow: t('routes.users'), url: '/' + route.users },
                        { nameToShow: t('users.new'), url: '' },
                    ]}
                />
            )}

            {isLoading ? (
                <Loading />
            ) : (
                <Formik
                    initialValues={getInitialValues()}
                    enableReinitialize
                    validationSchema={userSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        if (checkFlows(values.allowedFlows)) {
                            setSubmitting(true);
                            let normalizedDataFlows = values.allowedFlows.map(d => d.id);
                            let normalizedValues = {
                                user: { userName: values.name, userSurname: values.surname, userEmail: values.email, userObjectId: '', userId: !!id ? parseInt(id) : 0 },
                                structuresIds: values.userStructures.map((data: any) => data.value),
                                allowedFlows: allowedFlows.map(data => {
                                    if (normalizedDataFlows.includes(data.id)) return { ...data, isAllowed: true };
                                    return { ...data, isAllowed: false };
                                }),
                            };

                            toast.promise(userToEdit ? triggerEdit(normalizedValues) : triggerCreate(normalizedValues), {
                                loading: userToEdit ? t('generic.editOingoing') : t('generic.createOingoing'),
                                success: data => {
                                    setSubmitting(false);
                                    navigate('/' + route.users);
                                    if (loggedUser.userId === userToEdit?.userId) {
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 2000);
                                        return t('generic.reload');
                                    }
                                    return t('generic.success');
                                },
                                error: e => {
                                    setSubmitting(false);
                                    return t('generic.error');
                                },
                            });
                        } else {
                            AlertError(t('generic.errorFlows'));
                            setSubmitting(false);
                        }
                    }}>
                    {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
                        <FormWrapper>
                            <TextInput name='name' label={t('generic.name')} required />
                            <TextInput name='surname' label={t('generic.surname')} required />
                            <TextInput name='email' label={t('generic.email')} />

                            <>
                                <DivFlexRow>
                                    <input
                                        type='checkbox'
                                        id='selectAllStructures'
                                        checked={isChecked}
                                        onChange={e => {
                                            const allSelected = e.target.checked
                                                ? structures.map(s => ({ value: s.structureId, label: i18n.language === ELanguages.it ? s.structureNameIt : s.structureNameDe, id: s.structureId }))
                                                : [];

                                            setIsChecked(e.target.checked);
                                            setFieldValue('userStructures', allSelected);
                                        }}
                                    />
                                    <label htmlFor='selectAllStructures'>{t('users.selectAllStructures')}</label>
                                </DivFlexRow>
                                <SelectWrapper className='select-wrapper'>
                                    <label htmlFor='userStructures'>{t('structures.tableName')}</label>
                                    <Select
                                        inputId='userStructures'
                                        value={values.userStructures}
                                        isMulti
                                        name='userStructures'
                                        options={
                                            structures.map(s => ({ value: s.structureId, label: i18n.language === ELanguages.it ? s.structureNameIt : s.structureNameDe, id: s.structureId })) as any[]
                                        }
                                        onChange={data => {
                                            setFieldValue('userStructures', data);
                                            if (isChecked && values.userStructures.length !== data.length) {
                                                setIsChecked(!isChecked);
                                            }
                                        }}
                                    />
                                </SelectWrapper>
                            </>

                            {!!userToEdit && (
                                <SelectWrapper className='select-wrapper'>
                                    <label htmlFor='allowedFlows'>{t('generic.visibleFlow')}</label>
                                    <Select
                                        inputId='allowedFlows'
                                        value={values.allowedFlows}
                                        isMulti
                                        name='allowedFlows'
                                        options={allowedFlows.map(af => ({ value: af.id, label: af.name, id: af.id })) as any[]}
                                        onChange={data => setFieldValue('allowedFlows', data)}
                                    />
                                </SelectWrapper>
                            )}

                            <Btn text={!!userToEdit ? t('btns.edit') : t('btns.create')} type='button' onClick={() => handleSubmit()} disabled={isSubmitting} />
                        </FormWrapper>
                    )}
                </Formik>
            )}
        </Container>
    );
};

export default NewUser;
