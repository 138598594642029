import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';

import CustomSvg from './componets/Svg/CustomSvg';
import { ELanguages } from './localization';
import { IAllowedFlows } from './model';
import { IconBtn } from './componets/Buttons/Buttons';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Landing from './pages/Landing';
import Loading from './componets/Loading/Loading';
import { RouterProvider } from 'react-router-dom';
import { ToastWrapper } from './style';
import { buildPaths } from './route';
import { checkUser } from './api/fetch';
import { get } from 'lodash';
import { loginRequest } from './msal/azureAd';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface IAppProps {}

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const DivToastMsg = styled.div`
    display: flex !important;
    padding-left: 1rem;
`;

export const LoggedUserContext = createContext<{
    loggedUser: { userId: number; cf: string; msgs: { messageIt: string; messageDe: string; messageId: string }[]; role: string; allowedFlows: IAllowedFlows[] };
    setLoggedUser: Dispatch<
        SetStateAction<{
            userId: number;
            cf: string;
            role: string;
            msgs: { messageIt: string; messageDe: string; messageId: string }[];
            allowedFlows: IAllowedFlows[];
        }>
    >;
}>({ loggedUser: { userId: -1, cf: '', msgs: [], role: '', allowedFlows: [] }, setLoggedUser: () => {} });

const App = (props: IAppProps) => {
    const { accounts, instance } = useMsal();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [loggedUser, setLoggedUser] = useState<{
        userId: number;
        role: string;
        cf: string;
        msgs: { messageIt: string; messageDe: string; messageId: string }[];
        allowedFlows: IAllowedFlows[];
    }>({
        userId: -1,
        cf: '',
        msgs: [],
        role: '',
        allowedFlows: [],
    });
    const { user, isError } = checkUser(loggedUser.cf);

    const checkRedirect = async () => {
        setLoading(true);
        try {
            await instance.handleRedirectPromise();
            let activeAcc = instance.getActiveAccount();
            let tokenSilent;

            if (!activeAcc && accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
                tokenSilent = await instance.acquireTokenSilent({ account: accounts[0], ...loginRequest });
            }

            if (!!activeAcc) {
                tokenSilent = await instance.acquireTokenSilent({ account: activeAcc, ...loginRequest });
            }
            if (!!tokenSilent) {
                const cf = get(accounts, '[0].idTokenClaims.extension_fiscalNumber', '');
                setLoggedUser({ ...loggedUser, cf });
                localStorage.setItem('token', tokenSilent.accessToken);
            }
        } catch (err) {
            console.log(err);
            if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(loginRequest);
            } else {
                instance.logoutRedirect();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkRedirect();
    }, [accounts]);

    useEffect(() => {
        if (!!user && !!user.userId && loggedUser.userId !== user.userId) {
            setLoggedUser({ ...loggedUser, userId: user.userId, msgs: user.messages, role: user.roleNameIt, allowedFlows: user.allowedFlows ?? [] });
            if (user.messages.length > 0) {
                let message = i18n.language === ELanguages.it ? get(user, 'messages[0].messageIt', '') : get(user, 'messages[0].messageDe', '');
                if (message) {
                    toast.custom(
                        to => (
                            <div
                                className={`${to.visible ? 'animate-enter' : 'animate-leave'} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto d-flex ring-1 ring-black ring-opacity-5`}>
                                <div className='flex-1 w-0'>
                                    <DivToastMsg>
                                        <ToastWrapper>
                                            <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
                                        </ToastWrapper>
                                        <ToastWrapper>
                                            <blockquote className='blockquote'>
                                                <p className='mb-0'>{i18n.language === ELanguages.it ? get(user, 'messages[0].messageIt', '') : get(user, 'messages[0].messageDe', '')}</p>
                                            </blockquote>
                                        </ToastWrapper>
                                        <ToastWrapper>
                                            <IconBtn
                                                type='button'
                                                onClick={() => {
                                                    toast.dismiss(to.id);
                                                }}>
                                                <CustomSvg iconName='it-close-circle' className='icon icon-primary' />
                                            </IconBtn>
                                        </ToastWrapper>
                                    </DivToastMsg>
                                </div>
                            </div>
                        ),
                        { duration: 1000000 },
                    );
                }
            }
        }
    }, [user, loggedUser.userId]);

    const errorCode = get(isError, 'response.status', 0);
    return (
        <>
            <AuthenticatedTemplate>
                {loading || !user || loggedUser.userId < 0 ? (
                    <LoadingContainer>
                        {errorCode !== 404 ? (
                            <Loading />
                        ) : (
                            <>
                                <ToastWrapper>
                                    <CustomSvg iconName='it-warning-circle' className='icon icon-warning' />
                                </ToastWrapper>
                                <ToastWrapper>
                                    <blockquote className='blockquote'>
                                        <p className='mb-0'>{t('generic.userNotFound')}</p>
                                    </blockquote>
                                </ToastWrapper>
                            </>
                        )}
                    </LoadingContainer>
                ) : (
                    <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
                        <RouterProvider router={buildPaths(loggedUser.allowedFlows, loggedUser.role)} />
                    </LoggedUserContext.Provider>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Landing loading={loading} />
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
